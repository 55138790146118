
  /*New file*/
  .i-grid{
    display: inline-grid;
    grid-template-rows: 1fr 5fr 1fr;
    grid-template-columns: 1fr 8fr 4fr 1fr;
    height: 100vh;
    width: 100vw;
    color: #FFA389;
  }

  .i-container {
    grid-row: 2;
    grid-column: 2;
    display: flex;
    flex-direction: column;
    padding: 5vw;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }

  .i-menue{
    grid-row: 2;
    grid-column: 3;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    
  }

  .i-menue img{
    max-width: 100%;
  }

  .i-menue-img{
    height: 60vh;
  }
  .i-menue-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .i-menue-item-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    height: 60vh;
    justify-content: flex-end;
  }

  .i-menue-item{
    font-size: 2.8vh;
    color: #FCFCFC;
    cursor: pointer;
    transition: all 300ms;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    height: 10vh;
  }
  .i-menue-item:hover{
    color: #FFA389;
  }

  .i-intro{
    font-size: 1.2vw;
    animation: pulse 1s 1;
    font-family: 'Roboto Serif', sans-serif;
    font-weight: 400;

  } 
  .i-name {
    padding-top: 0.5vw;
    font-size: 3.5vw;
    font-weight: bold;
    animation: pulse 1s 1;
    animation-delay: 0.2s;
    font-family: 'Roboto', sans-serif;
    color: #4ADAFA;
  }
  .i-quote {
    font-size: 3.5vw;
    font-weight: bold;
    color: rgba(252,252,252, .75);
    animation: pulse 1s 1;
    animation-delay: 0.4s;
    font-family: 'Roboto', sans-serif;
    color: #52C644;
    margin-top: -0.3vw;
  }

  .i-description{
    font-size: 1.1vw;
    font-family: 'Roboto Serif', sans-serif;
    font-weight: 400;
    line-height: 1.4vw;
    width: 30vw;
    padding-top: 0.5vw;
    animation: pulse 1s 1;
    animation-delay: 0.6s;
    height: 3.5vw;
  }

  

  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50%{
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 700px) {
    .i-container {
      grid-column: 1 / -1;
      justify-content: center;
      align-items: center;
    }
    .i-intro{
      font-size: 5vw;
      text-align: left;
      width: 80vw;
      padding-bottom: 2vw;
    } 
    .i-name {
      font-size: 12vw;
      text-align: left;
      width: 80vw;
      padding-bottom: 2vw;
    }
    .i-quote {
      font-size: 8vw;
      text-align: left;
      width: 80vw;
      padding-bottom: 2vw;
    }
  
    .i-description{
      font-size: 4vw;
      width: 60vw;
      line-height: normal;
      text-align: left;
      width: 80vw;
    }
    .i-menue{
      display: none;
    }
  }
.btnX{
    border-width: 2px;
    border-color: #4ADAFA;
    color: #4ADAFA;
    border-radius: 15px;
    font-size: 1.23vw;
    padding: 0.8vw 1.2vw;
    font-weight: 400;
    border-style: solid;
    cursor: pointer;
  }
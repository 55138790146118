.pv-code{
    background-color: black;
    width: 80%;
    border-radius: 30px;
    font-size: 1.23vw;
    text-align: left;
    font-family: 'Roboto Serif', sans-serif;
    font-weight: 100;
    padding: 3vw 3vw;
    min-height: 9vw;
    margin-bottom: 7px;
  }

  .isHidden{
      opacity: 0;
      transition: all 50ms;
  }

  .tag{
      color: #4ADAFA;
  }
  .tagSpacing{
      padding-left: 2vw;
  }
  .pTextSpacing{
      padding-left: 2.5vw;
      font-size: 1.1vw;
  }

  .h1Text{
      color: #52C644;
  }
  .pText{
    color:#FCFCFC;
  }

  @media screen and (max-width: 700px) {
    .pv-code{
        font-size: 2.7vw;
        min-height: 20vw;
        padding: 5vw;
    }
    .pTextSpacing{
        font-size: 2.4vw;
        padding-left: 5vw;
    }
  }
.wpv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    row-gap: 20px;
}

.wpv-img{
    margin-top: 2vh;
    width: 40vw;
    aspect-ratio: 2 / 1;
}
.wpv-img img{
    max-width: 100%;
    max-height: 100%;
}
.wpv-code{
    width: 40vw;
    display: flex;
    justify-content: center;
}
.wpv-bottom-container{
    width: 40vw;
}
@media screen and (max-width: 700px) {
    .wpv-img{
        width: 80vw;
    }
    .wpv-code{
        width: 80vw;
    }
    .wpv-bottom-container{
        width: 80vw;
    }
}
.c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh
}
.c h1{
    color: #52C644;
    font-size: 2.3vw;
    font-family: 'Roboto', sans-serif;
}
.c p{
    color: #FFA389;
    padding: 2.5vh;
    font-size: 1.23vw;
    padding-left: 20vw;
    padding-right: 20vw;
    width: 35%;
}

.c img{
    width: 5.5vw;
}

@media screen and (max-width: 700px) {
    .c h1{
        font-size: 8vw;
    }
    .c p{
        color: #FFA389;
        padding: 2.5vh;
        font-size: 3vw;
        width: 80%;
    }
    
    .c img{
        width: 20vw;
    }
}
.pv {
    width: 80vw;
    height: 90vh;
    display: inline-grid;
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 2fr;
    gap: 10px;
    padding: 10px;
    align-items: center;
    font-weight: 300;
}
.pv h1{
  font-size: 2.3vw;
}
.pv p{
  font-size: 1.23vw;
}
.pv-opposit{
  grid-template-columns: 2fr 3fr;
}
.pv-opposit h1{
  font-size: 2.3vw;
}
.pv-opposit p{
  font-size: 1.23vw;
}
.pv-opposit .pv-bottom-container ul{
  list-style-type: circle;
  columns: 8vw 2;
}

.pv-iPhone {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    position: relative;
}
.pv-iPhone-opposit {
  grid-column: 1;
}
.pv-featureImg{
  width: 63%;
  padding-bottom: 1.65vw;
}
.pv-iphoneBorder{
  position: absolute;
  width: 100%;
}

.pv-content {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: baseline;
    text-align: left;
    font-size: 1.3vw;
    gap: 1vw;
    color: #FFA389;
}
.pv-content-opposit{
  grid-column: 2;
}
.pv-content h1{
  font-size: 2.3vw;
  font-weight: 800;
  color: #52C644;
}

.pv-feature {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 90%;
    margin-bottom: 10px;
}

.pv-feature-item {
background-color: #4ADAFA;
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  margin-right: 20px;
  align-items: center;
  color: black;
  font-weight: 600;
  cursor: pointer;
}


.pv-bottom-container{
  display: flex;
  gap: 30px;
  justify-content: left;
  font-size: 1.3vw;
  color: white;
  
}
.pv-bottom-container h2{
  font-size: 1.3vw;
  color:  #FFA389;
  font-weight: 500;
  padding-bottom: 20px;
  padding-left: 10px;
}

.pv-bottom-container ul{
  list-style-type: circle;
  columns: 12vw 2;
}

.pv-bottom-container li span { 
  color:  #4ADAFA;
 } 
.pv-links{
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
}


.pv-feature-item-highlight{
  background-color: #52C644;
}

@media screen and (max-width: 700px) {
  .pv{
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    height: auto;
  }
  .pv-opposit{
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    height: auto;
  }
  .pv-iPhone {
    grid-row: 2;
    grid-column: 1;
  }
  .pv-iPhone-opposit {
    grid-row: 2;
    grid-column: 1;
  }
  .pv-content-opposit{
    grid-row: 1;
    grid-column: 1;
  }

  .pv h1{
    font-size: 8vw;
  }
  .pv p{
    font-size: 4vw;
  }
  .pv-feature-item {
    font-size: 4vw;
  }
  .pv-iPhone {
    width: 65%;
    margin-left: 15vw;
  }
}
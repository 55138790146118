.nav-grid{
    width: 100vw;
    background-color: rgb(37, 43, 40);
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2vw;
    transition: all 500ms;
    z-index: 100;
}
.nav-grid-hidden{
    opacity: 0;
    pointer-events: none;
}

.nav-item{
    padding: 15px;
    font-size: 1.25rem;
    color:white
}

.nav-spacer{
    width: 8.4rem;
}

.nav-item:hover{
    color: chartreuse;
    cursor: pointer;
}

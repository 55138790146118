.pl {
  padding: 0px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.pl-texts {
  width: 65%;
}

.pl-title {
  font-size: 50px;
  font-weight: 600;
}

.pl-desc {
  margin: 20px 0px;
}

.pl-list-ios-content {
  padding: 20px;
}

.pl-list-content-web h1{
  width: 100%;
  text-align: left;
  color: #52C644;
  font-size: 2.3vw;
}

.pl-list-content-web {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  gap: 10px;
  padding-top: 15vh;
}

@keyframes shrink {
  from {
    height: 65vh;
  }
  to{
      height: 45vh;
  }
}


@keyframes grow {
  from {
    height: 45vh;
  }
  to{
      height: 65vh;
  }
}

@media screen and (max-width: 700px) {
  .pl {
    padding: 10px;
  }

  .pl-texts {
    width: 100%;
  }

  .pl-desc {
    display: none;
  }
  .pl-list-content-web h1{
    font-size: 8vw;
  }
  .pl-list-content-web {
    justify-content: center;
  }
}

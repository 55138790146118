.a{
  display: inline-grid;
  grid-template-rows: 1;
  grid-template-columns: 2fr 3fr;
  height: 100vh;
  width: 100vw;
}

.a-img{
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.a-img img{
  width: 65%;
}
.a-img-tint{
  position: absolute;
    width: 65%;
    aspect-ratio: 628/817;
    background-color:  #EE493A;
    opacity: 0.24;
    transition: all 500ms;
}
.a-img-tint:hover{
    opacity: 0;
}

.a-content{
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  padding: 10vw;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}
.a-title{
  font-size: 2.3vw;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-weight: bolder;
  color: #52C644;;
  padding: 10px;
  margin-top: -1vw;
}
.a-info{
  margin-top: 0.7vw;
  padding: 10px;
  font-size: 1.23vw;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #FFA389;
}
.a-skill{
  font-size: 1.23vw;
  color: white;
  display: flex;
  justify-content: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  padding-top: 1vw;
}

.a-skill ul{
  list-style-type: circle;
  columns: 8vw 2;
  padding-left: 2.3vw;
}
li{
  font-size: 2vw;
}
ul li{
  margin-bottom: -0.5vw;
}
li span{
  color:  #4ADAFA;
  font-size: 1.23vw;
  font-weight: 300;
}

@media screen and (max-width: 700px) {
  .a{
    grid-template-columns: 1fr;
    height: auto;
  }
  .a-img{
    display: none;
  }
  .a-content{
    grid-row: 1;
    grid-column: 1;
  }
  .a-title{
    font-size: 8vw;
    text-align: center;
  }
  .a-info{
    font-size: 4vw;
  }
  .a-skill{
    font-size: 8vw;
    justify-content: center;
  }
  
  .a-skill ul{
    padding-left: 5vw;
  }
  li{
    font-size: 8vw;
  }
  li span{
    font-size: 4vw !important;
  }
}
.bc{
    display: grid;
    grid-template-rows: 1fr 3fr;
    grid-template-columns: 1fr 2fr;
    column-gap: 2vw;
    text-align: left;
}
.bc-buttons{
    grid-row: 1 / -1;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    white-space: nowrap;
}
.bc-btn{
    display: flex;
    flex-direction: row;
    border-width: 2px;
    border-color: #4ADAFA;
    color: #4ADAFA;
    border-radius: 15px;
    font-size: 1.3vw;
    padding: 0.8vw 1.5vw;
    font-weight: 400;
    border-style: solid;
    cursor: pointer;
    width: 9vw;
    justify-content: center;
    margin-bottom: 1vw;
}
.bc-title{
    grid-row: 1;
    grid-column: 2;
    padding-left: 0.8vw;
    font-size: 1.3vw;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FFA389;
}
.bc-tech{
    grid-row: 2;
    grid-column: 2;
}
ul{
    list-style-type: circle;
    columns: 8vw 2;
    padding-left: 2.3vw;
}
li{
    font-size: 2vw;
    color:  #fcfcfc;
}
ul li{
    margin-bottom: -0.5vw;
}
li span{
    color:  #4ADAFA;
    font-size: 1.3vw;
    margin-top: -10px;
    font-weight: 300;
}
@media screen and (max-width: 700px) {
    .bc{
        row-gap: 2vw;
    }
    .bc-title{
        font-size: 4vw;
    }
    .bc-buttons{
        justify-content: flex-start;
        gap: 2vw;
    }
    .bc-btn{
        font-size: 3vw;
        width: 20vw;
    }
    .bc-tech ul{
        width: 55vw;
    }
}